import React, { useContext, useEffect } from 'react';
import { Segment, Divider, Breadcrumb, Dimmer, Loader } from 'semantic-ui-react';
import { RecordProfile, Notification, STATUS_TYPES, DateTimeUtils } from 'dyl-components';

import { Link, NavLink, Outlet, useLocation, useParams } from "react-router-dom";

import taskTypesActions from 'actions/task_types';
import contactPhoneActions from 'actions/contact_phone';
import contactEmailActions from 'actions/contact_email';

import { ProfileHeader } from './ProfileHeader';
import { AccountStatistics } from './AccountStatistics';
import { AccountTabs } from './AccountTabs';
import { AccountDetails } from './AccountDetails';

import StickyNotes from 'shared/StickyNotes';
import accountActions from 'actions/account';
import { useDispatch, useSelector } from 'react-redux';
import { StringUtils } from 'utils';
import { Error404, Error500 } from 'pages/ErrorPages';

import './index.scss';
import { ProductInterestsSection } from './ProductInterestsSection';
import recentsActions from 'actions/recents';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';
import { RecordInfo } from 'shared/RecordInfo';

import QuoteBuilderProvider, { ACTIONS, QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import sequenceTasksActions from 'actions/sequence_tasks';
import SidePanelProvider, { SidePanelContext } from 'shared/context/SidePanelProvider';
import orderActions from 'actions/order';
import rolesActions from 'actions/roles';

const Account = () => {
    const { isSidePanelOpen, onOpenSidePanel, onCloseSidePanel } = useContext(SidePanelContext);
    const { onOpenAddProducts } = useContext(QuoteBuilderContext);

    const { pathname } = useLocation();
    const tab = pathname.split('/')[3];

    const isDetailsSectionOpen = tab === undefined || tab === '';

    const account_id = Number(useParams().account_id);

    const account = useSelector(state => state.account.account);
    const isReadingAccount = useSelector(state => state.account.isReadingAccount || state.account.isReadingContactIds
        || state.contact_phone.isReadingPhones || state.contact_email.isReadingEmails);
    const accountError = useSelector(state => state.account.accountError);

    const { customer_status } = useSelector(state => state.account.account?.customer || {});

    const dispatch = useDispatch();
    const location = useLocation();
    const isDemo = location?.state?.isDemo;

    useEffect(() => {
        dispatch(accountActions.readAccount(account_id))
            .then(account => {
                const contactAssociatedWithPipeline = account?.customer?.customer_pipeline?.assocciated_contact?.id;
                if (Boolean(contactAssociatedWithPipeline)) {
                    dispatch(sequenceTasksActions.readContactTasks(contactAssociatedWithPipeline));
                }
                if (isDemo) {
                    onOpenAddProducts({ action: ACTIONS.DEMO });
                }
            });
        dispatch(accountActions.readContactIds(account_id));
        dispatch(contactPhoneActions.getContactPhones(account_id));
        dispatch(contactEmailActions.getContactEmails(account_id));
        dispatch(taskTypesActions.readTaskTypes())
        dispatch(accountActions.readContactsForPinning(account_id, { page: 1, limit: 100 }));
        dispatch(recentsActions.addRecent({ external_id: account_id, page_type: FAVORITE_TYPE.ACCOUNT }));
        dispatch(rolesActions.readRoles({ limit: 1000 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account_id, dispatch]);

    if (isReadingAccount) {
        return <Dimmer active><Loader active /></Dimmer>
    }

    if (!isReadingAccount && accountError) {
        return <Error500 message={'Something went wrong'} />
    }

    if (!isReadingAccount && !account?.id) {
        return <Error404 message={'Account not found'} />
    }

    const refresh = () => {
        dispatch(accountActions.readAccount(account_id));
    }

    const pinnedContacts = account?.pinned || [];

    const updateAccount = (id, payload) => {
        return dispatch(accountActions.updateAccount(id, payload));
    }

    const onPin = async (id) => {
        const updatedPins = pinnedContacts.map(contact => contact.id).slice(0);
        updatedPins.push(id);
        try {
            await updateAccount(account_id, { name: account?.name, pinned: updatedPins, primary_person_id: account?.primary_contact?.id });
            Notification.alert('Successfully pinned contact!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to pin contact', STATUS_TYPES.ERROR);
        }
    }

    const onUnpin = async (id) => {
        const updatedPins = pinnedContacts.filter(contact => contact.id !== id).map(contact => contact.id).slice(0);
        try {
            await updateAccount(account_id, { name: account?.name, pinned: updatedPins, primary_person_id: account?.primary_contact?.id });
            Notification.alert('Successfully unpinned contact!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            Notification.alert('Failed to unpin contact', STATUS_TYPES.ERROR);
        }
    }

    const onReplacePinnedContact = async (previouslyPinnedContact, contactToPin) => {
        const pins = pinnedContacts.map(contact => contact.id).slice(0);
        const indexOfContactToUnpin = pins.findIndex(id => id === previouslyPinnedContact);

        if (indexOfContactToUnpin !== -1) {
            try {
                const updatedPins = [
                    ...pins.slice(0, indexOfContactToUnpin),
                    contactToPin,
                    ...pins.slice(indexOfContactToUnpin + 1)
                ];
                await updateAccount(account_id, { name: account?.name, pinned: updatedPins, primary_person_id: account?.primary_contact?.id });
                Notification.alert('Successfully replaced pinned contact!', STATUS_TYPES.SUCCESS);
                refresh();
            } catch (e) {
                Notification.alert('Failed to replace pinned contact', STATUS_TYPES.ERROR);
            }
        };
    }

    const onSetPrimary = async (id, replace) => {
        try {
            await updateAccount(account_id, {
                name: account?.name,
                pinned: pinnedContacts.map(contact => contact.id).filter(contact_id => contact_id !== id),
                primary_person_id: id
            });
            Notification.alert(`Successfully ${replace ? 'replaced' : 'set'} primary contact!`, STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            Notification.alert(`Failed to ${replace ? 'replaced' : 'set'} primary contact`, STATUS_TYPES.ERROR);
        }
    }

    const created = account?.created ? DateTimeUtils.formatEpoch(account.created, DateTimeUtils.WORD_DATETIME_FORMAT) : '';
    const last_modified = account?.activity ? DateTimeUtils.formatEpoch(account.activity, DateTimeUtils.WORD_DATETIME_FORMAT) : '';

    if (account?.account_type) {
        const account_type = account?.account_type;
        return (
            <div className='AccountPage'>
                <RecordProfile
                    isDetailsSectionOpen={isDetailsSectionOpen}
                    header={(
                        <ProfileHeader
                            name={account.name}
                            id={account_id}
                            type='Account'
                            isSidePanelOpen={isSidePanelOpen}
                            breadcrumbs={(
                                <Breadcrumb>
                                    {!Boolean(customer_status) && (
                                        <React.Fragment>
                                            <Breadcrumb.Section as={Link} to='/accounts' link>Accounts</Breadcrumb.Section>
                                            <Breadcrumb.Divider />
                                        </React.Fragment>
                                    )}
                                    <Breadcrumb.Section as={NavLink} to={`/accounts?${(new URLSearchParams({ page: 1, filter: customer_status ? 'customer' : account_type })).toString()}`} link>{customer_status ? 'Customers' : account_type === 'business' ? 'Businesses' : 'Households'}</Breadcrumb.Section>
                                    {account.master_account?.id ? (
                                        <React.Fragment>
                                            <Breadcrumb.Divider />
                                            <Breadcrumb.Section as={NavLink} to={`/master_account/${account.master_account.id}`} link>{account.master_account.name}</Breadcrumb.Section>
                                        </React.Fragment>
                                    ) : null}
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>{account.name}</Breadcrumb.Section>
                                </Breadcrumb>
                            )}
                        />
                    )}
                    details={(
                        <AccountDetails
                            onPin={onPin}
                            onReplacePinnedContact={onReplacePinnedContact}
                            onUnpin={onUnpin}

                            onSetPrimary={onSetPrimary}
                            isSidePanelOpen={isSidePanelOpen}
                            account_type={account?.account_type || ''}
                        />
                    )}
                    tabs={(
                        <AccountTabs
                            account_id={account_id}
                            account_type={StringUtils.capitalize(account?.account_type || '')}
                        />
                    )}
                    tabContent={(
                        <Outlet />
                    )}
                    rightHandContent={(
                        <React.Fragment>
                            <StickyNotes record_id={account_id} />
                            <Divider />
                            <Segment size='tiny' style={{ paddingRight: '2.5em' }}>
                                <AccountStatistics
                                    account_id={account_id}
                                    contacts_count={account.counts.contact_count}
                                    leads_count={account.counts.lead_count}
                                    opportunities_count={account.counts.opportunity_count}
                                />
                            </Segment>
                            <Segment>
                                <ProductInterestsSection
                                    account_id={account_id}
                                />
                            </Segment>
                            <Segment>
                                <RecordInfo
                                    created={created}
                                    last_modified={last_modified}
                                    assignees={account.assignees}
                                    record_id={account_id}
                                    refresh={refresh}
                                    record_source={"User Input"}
                                />
                            </Segment>
                        </React.Fragment>
                    )}
                    onToggleSidePanel={isOpen => {
                        !isOpen ? onCloseSidePanel() : onOpenSidePanel();
                    }}
                    isSidePanelOpen={isSidePanelOpen}
                />
            </div>
        )
    }

    return null;
}

const Container = (props) => {
    const params = useParams();
    const account_id = Number(params.account_id);
    const dispatch = useDispatch();
    return (
        <SidePanelProvider>
            <QuoteBuilderProvider refreshAccountInformation={() => {
                dispatch(orderActions.read(account_id));
                dispatch(orderActions.readRecentOrder(account_id));
            }}>
                <Account {...props} />
            </QuoteBuilderProvider>
        </SidePanelProvider>
    );
}

export default Container;
