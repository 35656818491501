import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function supplyChainNetworkReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCOUNT_TERM, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingAccountTerm: true, term: null };
        case getNamedAction(ACTION_NAMES.ACCOUNT_TERM, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingAccountTerm: false, term: action.data };
        case getNamedAction(ACTION_NAMES.ACCOUNT_TERM, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingAccountTerm: false, term: null };

        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingProducts: true, products: [], count: 0 };
        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingProducts: false, products: action.data?.data || [], count: action.data?.count };
        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingProducts: false, products: [], count: 0 };

        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isModifyingSubscription: true };
        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isModifyingSubscription: false };
        case getNamedAction(ACTION_NAMES.SUBSCRIPTION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isModifyingSubscription: false };

        case getNamedAction(ACTION_NAMES.NEXT_INVOICE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingNextInvoice: true, next_invoice: {} };
        case getNamedAction(ACTION_NAMES.NEXT_INVOICE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingNextInvoice: false, next_invoice: action.data };
        case getNamedAction(ACTION_NAMES.NEXT_INVOICE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingNextInvoice: false, next_invoice: {} };

        case getNamedAction(ACTION_NAMES.CURRENT_SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCurrentProducts: true, current_products: [], current_products_count: 0 };
        case getNamedAction(ACTION_NAMES.CURRENT_SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCurrentProducts: false, current_products: action.data?.data || [], current_products_count: action.data?.count };
        case getNamedAction(ACTION_NAMES.CURRENT_SUBSCRIPTION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCurrentProducts: false, current_products: [], current_products_count: 0 };

        case getNamedAction(ACTION_NAMES.OUTSTANDING_INVOICES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingOutstandingInvoices: true, outstanding_invoices: [] };
        case getNamedAction(ACTION_NAMES.OUTSTANDING_INVOICES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingOutstandingInvoices: false, outstanding_invoices: action.data };
        case getNamedAction(ACTION_NAMES.OUTSTANDING_INVOICES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingOutstandingInvoices: false, outstanding_invoices: [] };

        case getNamedAction(ACTION_NAMES.PLAN, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingPlan: true, plan: null };
        case getNamedAction(ACTION_NAMES.PLAN, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingPlan: false, plan: action.data };
        case getNamedAction(ACTION_NAMES.PLAN, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingPlan: false, plan: null };

        default:
            return state;
    }
}


export default supplyChainNetworkReducer;
