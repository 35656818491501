const initialState = {
    isReadingAccountTerm: false,
    term: null,
    isReadingProducts: false,
    products: [],
    isModifyingSubscription: false,
    next_invoice: {},
    isReadingCurrentProducts: false,
    current_products: [],
    current_products_count: 0,
    isReadingOutstandingInvoices: false,
    outstanding_invoices: [],
    isReadingPlan: false,
    plan: null
}

export default initialState;
