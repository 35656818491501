import React, { useEffect, useRef } from 'react';
import { Modal, Notification, STATUS_TYPES, generateResolver, VALIDATORS, yup } from 'dyl-components';
import { Button, Form, Header, Icon, Input, Label, List, Popup, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomGroupUtils, PhoneUtil, RecordUtils, StringUtils, ValidationUtils } from 'utils';

import accountActions from 'actions/account';
import { STATES } from 'shared/constants/STATES';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import './AddAccountModal.scss';
import contactsActions from 'actions/contacts';
import masterAccountActions from 'actions/master_account';
import tasksActions from 'actions/tasks';
import customFieldsGroupActions from "actions/custom_fields_group";
import RecordPhonesForm from 'shared/forms/RecordPhonesForm';
import RecordEmailsForm from 'shared/forms/RecordEmailsForm';
import LocationUtils from 'utils/LocationUtils';
import CustomerAccountInfo from 'shared/forms/CustomerAccountInfo';
import customersActions from "actions/customers";
import contactActions from 'actions/contact';
import BusinessAdditionalInfo from "./BusinessAdditionalInfo";
import CustomData from 'shared/CustomData';
import campaignsAction from "actions/campaigns";
import ContactsToLink from 'shared/ContactsToLink';
import { applyEmailUniquenessAcrossContacts, applyPhonesSchema } from 'shared/schemas/contact/commsSchema';

const contactsSchema = yup.array(yup.object({
    new: yup.boolean(),
    first_name: yup.string().when("new", {
        is: true,
        then: () => VALIDATORS.FIRST_NAME().no_whitespace_only().required('This field is required')
    }),
    last_name: yup.string().when("new", {
        is: true,
        then: () => VALIDATORS.LAST_NAME().no_whitespace_only().required('This field is required')
    }),
    phones: yup.array().when("new", {
        is: true,
        then: schema => applyPhonesSchema(schema)
    }),
    emails: yup.array().when("new", {
        is: true,
        then: schema => schema.when("phones", {
            is: phones => {
                return phones?.some((phone) => phone.value !== '') === true;
            },
            then: schema => schema.of(yup.object().shape({
                value: applyEmailUniquenessAcrossContacts(VALIDATORS.EMAIL_ADDRESS().maxlength(256))
            })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList),
            otherwise: schema => schema.of(yup.object().shape({
                value: applyEmailUniquenessAcrossContacts(VALIDATORS.EMAIL_ADDRESS().maxlength(256).required('This field is required'))
            })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList)
        })
    }),
    website: VALIDATORS.WEBSITE(),
    state: yup.mixed().when("new", {
        is: true,
        then: schema => schema.required('This field is required')
    }),
    suffix: VALIDATORS.SUFFIX(),
    department: yup.string().maxlength(64),
    job_title: yup.string().maxlength(60),
    street: yup.string().maxlength(100),
    apartmentUnitOrFloor: yup.string().maxlength(12),
    city: yup.string().maxlength(60),
    zip: VALIDATORS.US_POSTAL_CODE(),

    role: yup.string().when("new", {
        is: false,
        then: schema => schema.required('This field is required')
    })
}, ['emails', 'phones']));

const ModalContent = ({
    onClose,

    master_account_id,
    refreshFromLinking,

    contactIdToLink,
    account_type
}) => {
    const {
        isCreating,
        isReadingCustomFields,
        group,
        fields
    } = useSelector(state => {
        const { isCreatingAccount, isCreatingLocation } = state.account;
        const { isCreating: isCreatingContacts } = state.contacts;
        const custom_groups = (state.custom_fields_group.modal_standard_group.children || []).filter(group => !group.standard)
        const fields = state.custom_fields_group.modal_standard_group?.fields?.data || [];
        return {
            isCreating: isCreatingAccount || isCreatingLocation || isCreatingContacts || state.master_account.isLinkingAccounts || state.customers.isCreatingCustomer || state.contact.contactBeingUpdated,
            isReadingCustomFields: state.custom_fields_group.isReadingContactStandardFieldGroup || state.custom_fields_group.isReadingModalStandardFieldGroup,
            group: custom_groups,
            fields
        };
    });
    const primaryContactRef = useRef(null);

    const custom_groups = CustomGroupUtils.formatFields(group);

    const {
        formState: { isDirty, isValid },
        control,
        handleSubmit,
        clearErrors,
        setError,
        trigger,
        resetField,
        watch,
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',

            phones: [{ value: '', type: "Cell", main: true }],
            emails: [{ value: '', type: "Work", main: true }],

            address_label: account_type === 'business' ? 'Business' : 'Home',
            street: '',
            apartmentUnitOrFloor: '',
            city: '',
            state: '',
            zip: '',
            campaign_converted: null,

            addedContacts: [{
                main: false,
                first_name: '',
                last_name: '',
                suffix: '',
                emails: [
                    { value: '', type: "Work", main: true }
                ],
                phones: [
                    { value: '', type: "Cell", main: true }
                ],
                role: '',
                job_title: '',

                address_label: account_type === 'business' ? 'Business' : 'Home',
                street: '',
                apartmentUnitOrFloor: '',
                city: '',
                state: '',
                zip: '',

                business_name: '',
                industry_sector: null,
                sub_industry: null,
                employee_size: 0,
                annual_revenue: '',
                products_sold: '',

                household_name: '',
                household_type: null,
                household_members: 0,
                number_of_children: 0,
                annual_household_income: ''
            }],

            isAddCustomerToggled: false,
            customerPipeline: '',
            customerStage: '',
            customer_type: '',

            ...(account_type === 'business' ? {
                industry_sector: null,
                sub_industry: null,
                website: '',
            } : {
                account_household_type: null,
                monthly_income: '',
                annual_income: '',

                members: 0,
                number_of_children: 0
            }),

            children: custom_groups,
            isDemo: false
        },
        resolver: generateResolver({
            name: VALIDATORS.BUSINESS_NAME().no_whitespace_only().required(),
            addedContacts: contactsSchema,
            phones: yup.array().when("addedContacts", {
                is: contacts => {
                    return contactIdToLink !== undefined || contacts.some(({ phones }) => phones.some(phone => phone.value !== '')) === true;
                },
                then: schema => schema.of(yup.object().shape({
                    value: VALIDATORS.PHONE_NUMBER()
                })).test('no_repeating_phone', "Phone already in the list", ValidationUtils.checkForDuplicatePhonesInTheList),
                otherwise: schema => schema.of(yup.object().shape({
                    value: VALIDATORS.PHONE_NUMBER().required('This field is required')
                })).test('no_repeating_phone', "Phone already in the list", ValidationUtils.checkForDuplicatePhonesInTheList),
            }),
            emails: yup.array().when("addedContacts", {
                is: contacts => {
                    return contactIdToLink !== undefined || contacts.some(({ emails }) => emails.some(email => email.value !== '')) === true;
                },
                then: schema => schema.of(yup.object().shape({
                    value: VALIDATORS.EMAIL_ADDRESS().maxlength(256)
                })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList),
                otherwise: schema => schema.of(yup.object().shape({
                    value: VALIDATORS.EMAIL_ADDRESS().required('This field is required').maxlength(256)
                })).test('no_repeating_email', "Email already in the list", ValidationUtils.checkForDuplicateEmailsInTheList)
            }),
            state: yup.string().required("This field is required"),
            street: yup.string().maxlength(100),
            apartmentUnitOrFloor: yup.string().maxlength(12),
            city: yup.string().maxlength(60),
            zip: VALIDATORS.US_POSTAL_CODE(),
            children: CustomGroupUtils.generateValidationSchema(custom_groups)
        })
    });

    const dispatch = useDispatch();

    const { fields: phones, append: addPhone, remove: removePhone, update: updatePhone } = useFieldArray({
        control,
        name: 'phones'
    });

    const { fields: emails, append: addEmail, remove: removeEmail, update: updateEmail } = useFieldArray({
        control,
        name: 'emails'
    });

    const watchedContacts = watch("addedContacts");

    useEffect(() => {
        const primaryContact = watchedContacts.find(contact => contact.main)
        if (primaryContact !== primaryContactRef.current) {
            primaryContactRef.current = primaryContact;
            setValue('campaign_converted', null)
        }
        if (primaryContact !== undefined && primaryContact.contact_id) {
            dispatch(campaignsAction.readPersonCampaignConverted({ person_id: primaryContact.contact_id, campaign_conversion: "customer" }));
        } else {
            setValue('campaign_converted', null)
        }
    }, [watchedContacts, setValue, dispatch])

    const navigate = useNavigate();

    const onLinkContactsToAccount = (contacts, account_id) => {
        return dispatch(accountActions.linkContacts(account_id, contacts))
    }

    const updateTasks = (contact_id, account_id) => {
        return dispatch(tasksActions.updateTasks(-1, { contact_id, account_id }));
    }

    const linkContactsToAccount = async (contacts, account_id) => {
        if (contactIdToLink) {
            await onLinkContactsToAccount([
                { person_id: contactIdToLink, primary_person: false }
            ], account_id);
            return await updateTasks(contactIdToLink, account_id);
        }
        const newContactsToLink = contacts.filter(contact => !contact.contact_id && contact.first_name && contact.last_name).map(contact => ({
            primary: contact.main || false,
            contact_type: 'person',
            custom_data: {
                fields: CustomGroupUtils.extractPersonFields(contact),
                children: {
                    ...CustomGroupUtils.extractBusinessAndHouseholdDetails(contact)
                }
            },
            email: contact.emails.filter(email => Boolean(email.value)).map(email => ({
                main: email.main,
                email: email.value,
                email_type: email.type
            })),
            first_name: contact.first_name,
            last_name: contact.last_name,
            location: [
                {
                    street: contact.street,
                    additional_street: contact.apartmentUnitOrFloor,
                    city: contact.city,
                    state: contact.state,
                    zip: contact.zip,
                    label: contact.address_label
                }
            ],
            phone: contact.phones.filter(phone => phone.value).map(phone => ({
                main: phone.main,
                phone: phone.value,
                phone_type: phone.type
            })),
            influence: contact.role || 'influencer',
            suffix: contact.suffix || '',
            social_media: [],
            website: contact.website,
            date_of_birth: contact.birthday,
            job_title: contact.job_title
        }));

        const contactsToLink = [];

        if (newContactsToLink.length) {
            try {
                const newContacts = await dispatch(contactsActions.createContact(newContactsToLink));
                contactsToLink.push(...newContacts.map((id, index) => ({
                    person_id: id,
                    primary_person: newContactsToLink[index]?.primary,
                    influence: newContactsToLink[index]?.role || 'influencer',
                    new: true
                })));
            } catch (e) {
                console.log(e);
            }
        }

        const existingContactsToLink = contacts.filter(contact => contact.contact_id).map(contact => ({
            person_id: contact.contact_id,
            primary_person: contact.main || false,
            influence: contact.role || "influencer"
        }));

        contactsToLink.push(...existingContactsToLink);

        if (contactsToLink.length) {
            try {
                contactsToLink.filter(contact => !contact.new).forEach(async contact => {
                    await dispatch(contactActions.updateContact(contact.person_id, { influence: contact.influence || 'influencer' }));
                });
                await onLinkContactsToAccount(contactsToLink, account_id);
                await updateTasks(contactsToLink.map((contact_id) => contact_id.person_id ), account_id);
                return contactsToLink.find(contact => contact.primary_person)?.person_id || contactsToLink[0]?.person_id;
            } catch (e) {
                console.log(e);
            }
        }

        return Promise.resolve();
    }

    const onCreate = async (data) => {
        const custom_data = {
            children: CustomGroupUtils.groupAndFlatten({ children: data.children }).children,
            fields: account_type === 'business' ? CustomGroupUtils.extractBusinessDetails(data) : CustomGroupUtils.extractHouseholdDetails(data)
        };

        const {
            address_label,
            street,
            apartmentUnitOrFloor,
            city,
            state,
            zip,
            isAddCustomerToggled,
            addedContacts,
            phones,
            emails,
            campaign_converted,
            customerStage
        } = data;

        const payload = [
            {
                account_type: account_type,
                custom_data,
                phone: phones.filter((phone) => phone.value).map(({ value, main, type }) => ({
                    main,
                    phone: PhoneUtil.getUnformatted(value),
                    phone_type: type
                })),
                location: [
                    {
                        street,
                        additional_street: apartmentUnitOrFloor,
                        city,
                        state,
                        zip,
                        label: address_label
                    }
                ],
                email: emails.filter(emailItem => emailItem.value).map(({ value: email, main, type }) => ({
                    main,
                    email,
                    email_type: type
                })),
                name: data.name
            }
        ];

        try {
            const [{ id: account_id }] = await dispatch(accountActions.createAccount(payload));
            if (master_account_id) {
                await dispatch(masterAccountActions.linkAccounts(master_account_id, [account_id]));
            }
            const owner_id = await linkContactsToAccount(addedContacts, account_id);
            if (isAddCustomerToggled) {
                await dispatch(customersActions.createCustomer({
                    account_id,
                    owner_id,
                    campaign_id: campaign_converted,
                    custom_data: {
                        fields: CustomGroupUtils.extractCustomerDetails(data)
                    },
                    ...(customerStage ? { pipeline_stage_id: Number(customerStage) } : {})
                }));
            }
            Notification.alert(`Successfully ${contactIdToLink ? 'linked' : 'created'} account!`, STATUS_TYPES.SUCCESS);
            onClose();
            const isDemo = data.isDemo;
            if (master_account_id && !isDemo) {
                refreshFromLinking();
            } else {
                navigate(`/account/${account_id}`, { state: { isDemo } });
            }
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to ${contactIdToLink ? 'link' : 'create'} account`, STATUS_TYPES.ERROR);
        }
    }

    const hasPrimaryContact = watchedContacts.some(contact => contact.main) === true;

    const phonesAndEmails = [
        <Form.Field
            label={(
                <label>
                    <Icon link onClick={() => {
                        addPhone({ value: '', type: 'Cell', main: false })
                    }} className='fas fa-plus-circle' color='primary' />
                    Phone <span className='AccountModal__asterisk'>*</span>
                </label>
            )}
            control={List}
        >
            <RecordPhonesForm
                control={control}
                phones={phones}
                onRemove={(index) => {
                    RecordUtils.removeItem(phones, index, updatePhone, removePhone);
                }}
                updateMain={(onChange) => {
                    RecordUtils.updateMain(phones, updatePhone, onChange);
                }}
                checkDuplicates={() => {
                    return []
                }}
                contact_id={null}
                setError={setError}
            />
        </Form.Field>,
        <Form.Field
            label={(
                <label>
                    <Icon link onClick={() => {
                        addEmail({ value: '', type: 'Work', main: false })
                    }} className='fas fa-plus-circle' color='primary' />
                    Email <span className='AccountModal__asterisk'>*</span>
                </label>
            )}
            control={List}
        >
            <RecordEmailsForm
                control={control}
                emails={emails}
                onRemove={(index) => {
                    RecordUtils.removeItem(emails, index, updateEmail, removeEmail);
                }}
                updateMain={(onChange) => {
                    RecordUtils.updateMain(emails, updateEmail, onChange);
                }}
                checkDuplicates={() => {
                    return []
                }}
                contact_id={null}
                setError={setError}
            />
        </Form.Field>
    ];

    return (
        [
            <Modal.Content scrolling>
                <Form noValidate>
                    <Form.Group widths='equal'>
                        <Controller
                            name='name'
                            control={control}
                            render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                <Form.Field
                                    required
                                    control='div'
                                    label={account_type === 'business' ? 'Business Name' : 'Household Account Name'}
                                    error={error?.message}
                                >
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        placeholder={account_type === 'business' ? 'Enter business name' : 'Type Household Account Name'}
                                    />
                                </Form.Field>
                            )}
                        />
                        {account_type === 'business' ? (
                            <Controller
                                name='industry_sector'
                                control={control}
                                render={({ field: { name: industryName, onChange, value: industryValue } }) => {
                                    const industryOptions = CustomGroupUtils.getFieldOptions(fields, industryName);
                                    return [
                                        <Controller
                                            control={control}
                                            name='sub_industry'
                                            render={({ field: { name: subIndustryName, onChange: onSubIndustryChange } }) => (
                                                <Form.Select
                                                    name={industryName}
                                                    value={industryValue}
                                                    onChange={(_, { value }) => {
                                                        onChange({ target: { name: industryName, value } });
                                                        onSubIndustryChange({ target: { name: subIndustryName, value: '' } })
                                                    }}
                                                    label='Industry/Sector'
                                                    placeholder='Select industry/Sector'
                                                    selectOnBlur={false}
                                                    options={industryOptions} loading={isReadingCustomFields}
                                                    clearable
                                                />
                                            )}
                                        />,
                                        <Controller
                                            name='sub_industry'
                                            control={control}
                                            render={({ field: { name, onChange, value } }) => (
                                                <Form.Select
                                                    name={name}
                                                    value={value}
                                                    onChange={(_, { value }) => {
                                                        onChange({ target: { name, value } });
                                                    }}
                                                    label='Sub-Industry'
                                                    placeholder='Select sub-industry'
                                                    selectOnBlur={false}
                                                    options={industryOptions?.find(industry => (industry.value === industryValue))?.sub_options || []}
                                                    loading={isReadingCustomFields}
                                                    clearable
                                                />
                                            )} />
                                    ];
                                }}
                            />
                        ) : phonesAndEmails}
                    </Form.Group>
                    {account_type === 'business' ? (
                        <Form.Group widths='equal'>
                            {phonesAndEmails}
                            <Controller
                                name='website'
                                control={control}
                                render={({ field: { name, onChange, value } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        label='Website'
                                        placeholder='Enter business website'
                                    />
                                )}
                            />
                        </Form.Group>
                    ) : [
                        <Form.Group widths={'equal'}>
                            <Controller
                                name='account_household_type'
                                control={control}
                                render={({ field: { name, onChange, value } }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label='Household Type'
                                        placeholder='Select household type'
                                        selectOnBlur={false}
                                        options={CustomGroupUtils.getFieldOptions(fields, name)}
                                    />
                                )}
                            />
                            <Controller
                                name={`monthly_income`}
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label='Monthly Income'
                                    />
                                )}
                            />
                            <Controller
                                name={`annual_income`}
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label='Annual Income'
                                    />
                                )}
                            />
                        </Form.Group>,
                        <Form.Group widths={3}>
                            <Controller
                                name={`members`}
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label='# Members'
                                    />
                                )}
                            />
                            <Controller
                                name={`number_of_children`}
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label='# Children'
                                    />
                                )}
                            />
                        </Form.Group>
                    ]}
                    <Form.Field
                        label='Address'
                        control={React.Fragment}
                    >
                        <Form.Group widths='equal'>
                            <Controller
                                name='address_label'
                                control={control}
                                render={({ field: { name, onChange, value } }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        placeholder='Select Address Label'
                                        selectOnBlur={false}
                                        options={LocationUtils.getAddressLabelOptions()}
                                    />
                                )}
                            />
                            <Controller
                                name='street'
                                control={control}
                                render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        placeholder='Street Address'
                                        error={error?.message}
                                    />
                                )}
                            />
                            <Controller
                                name='apartmentUnitOrFloor'
                                control={control}
                                render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        placeholder='Apt, unit building, floor #'
                                        error={error?.message}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Controller
                                name='city'
                                control={control}
                                render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        placeholder='City'
                                        error={error?.message}
                                    />
                                )}
                            />
                            <Controller
                                name='state'
                                control={control}
                                render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        placeholder='State *'
                                        search
                                        options={STATES.map(state => ({ ...state, value: state.key }))}
                                        error={error?.message}
                                    />
                                )}
                            />
                            <Controller
                                name='zip'
                                control={control}
                                render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } })
                                        }}
                                        error={error?.message}
                                        placeholder='Zipcode'
                                    />
                                )}
                            />
                        </Form.Group>
                    </Form.Field>
                    {!contactIdToLink && <ContactsToLink trigger={trigger} watch={watch} account_type={account_type} control={control} setError={setError} clearErrors={clearErrors} />}
                    {account_type === 'business' && [
                        <Header as='h3'>Additional Info</Header>,
                        <BusinessAdditionalInfo control={control} />
                    ]}
                    <Header as='h3' color='primary'>Custom Data</Header>
                    <CustomData
                        control={control}
                        isEditing
                        padded
                    />
                    <CustomerAccountInfo
                        control={control}
                        segmentToggleName='isAddCustomerToggled'
                        pipelineName='customerPipeline'
                        stageName='customerStage'
                        typeName='customer_type'
                        demoToggleName='isDemo'
                        resetField={resetField}
                        isCampaignConvertedDisabled={!hasPrimaryContact}
                    />
                </Form>
            </Modal.Content>,
            <Modal.Actions>
                <Controller
                    control={control}
                    name='isAddCustomerToggled'
                    render={({ field: { value } }) => {
                        const isValidCustomerAccount = !(value && !hasPrimaryContact);
                        return (
                            <>
                                {!isValidCustomerAccount && (
                                    <Popup
                                        trigger={<Icon size='large' className='fa-solid fa-triangle-exclamation WarningRed' />}
                                        open
                                        content='A primary contact is required to create a customer account'
                                        className='AddAccount__error'
                                        position='left center'
                                        positionFixed />
                                )}
                                <Button disabled={!isDirty || !isValid || isCreating || !isValidCustomerAccount} primary onClick={handleSubmit(onCreate)}
                                    loading={isCreating}>Save</Button>
                            </>

                        );
                    }}
                />

            </Modal.Actions>
        ]
    )
}

const AddAccountModal = ({
    onClose,

    master_account_id,
    refreshFromLinking,

    contactIdToLink,
    account_type
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(customFieldsGroupActions.getModalStandardGroup({ group_name: `${account_type}_account` }));
        if (!contactIdToLink) {
            dispatch(customFieldsGroupActions.getContactStandardGroup({ group_name: 'person' }));
        }
    }, [dispatch, account_type, contactIdToLink]);

    const isReadingCustomFields = useSelector(state => state.custom_fields_group.isReadingContactStandardFieldGroup || state.custom_fields_group.isReadingModalStandardFieldGroup);

    return (
        <React.Fragment>
            <Modal.Header>
                Add New {!contactIdToLink && (
                    <div className='PhoneEmailInfo'>
                        <Label pointing='right' color='black'>{StringUtils.capitalize(account_type)} email and phone number OR a {account_type} contact is
                            required</Label>
                        <Icon className='fas fa-circle-info' color='primary' />
                    </div>
                )}
            </Modal.Header>
            {isReadingCustomFields ? (
                <Segment loading basic></Segment>
            ) : (
                <ModalContent
                    onClose={onClose}
                    account_type={account_type}
                    contactIdToLink={contactIdToLink}
                    master_account_id={master_account_id}
                    refreshFromLinking={refreshFromLinking}
                />
            )}
        </React.Fragment>
    )
}

export default AddAccountModal;
