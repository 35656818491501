import { Table, Button, Person, TableLoader } from "dyl-components";
import { useSelector } from "react-redux";
import { Grid, Icon } from "semantic-ui-react";

const Users = () => {
    const { summarized_users, isReadingSummarizedUsers } = useSelector(state => state.user);
    return (
        <Table>
            <Table.Header primary>
                <Table.Row>
                    <Table.HeaderCell colspan={2}>
                        <Icon className="fas fa-user-lock" /> User Account
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {isReadingSummarizedUsers ? (
                    <Table.Row>
                        <TableLoader isLoading colspan={2} />
                    </Table.Row>
                ) : summarized_users?.map(user => (
                    <Table.Row key={user.user_id}>
                        <Table.Cell>
                            <Person 
                                popup={false}
                                username={`${user.first_name} ${user.last_name} ${user.is_owner && `(Owner)`}`} 
                                email={user.email}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Button floated='right' disabled>Simulate</Button>
                            <Button floated='right'>Resend Email</Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}

const AdminSettings = ({ onGenerateAccount }) => {
    const { summarized_users, isReadingSummarizedUsers } = useSelector(state => state.user);
    return (
        <Grid>
            {!isReadingSummarizedUsers && !summarized_users?.length && (
                <Grid.Row>
                    <Grid.Column>
                        <Button primary onClick={onGenerateAccount}>
                            Generate Account
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column width={10}>
                    <Users />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default AdminSettings;
