import "./index.scss";
import React from "react";
import {
    generateResolver,
    Modal,
    Notification,
    STATUS_TYPES,
    yup,
} from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import { VALIDATORS } from "dyl-components";
import authActions from "actions/auth/auth";
import assignActions from "actions/assign";
import { QAOptionsUtils } from "utils";
import userActions from "actions/user";
import companyActions from "actions/company";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";
import UserForm from "./UserForm";
import contactActions from "actions/contact";
import contactEmailActions from "actions/contact_email";

const AddUserModal = ({ contact_id, open, onClose, customer, company }) => {
    const { isCreating, roles, contactOptions, isCheckingDuplicates } =
        useSelector((state) => ({
            isCheckingDuplicates:
                state.user.isFindingUserByEmail ||
                state.contact_duplicates.isCheckingDuplicates,
            isCreating:
                state.contact_email.isUpdatingEmail ||
                state.contact.contactBeingUpdated ||
                state.users.isCreatingUser ||
                state.auth.isCreatingUser ||
                state.team.teamWhereUsersAreBeingAdded ||
                state.company.isCreatingCompany,
            roles: state.roles.roles.map(({ id, name }) => ({
                key: id,
                value: id,
                text: name,
            })),
            ...(contact_id
                ? {
                      contactOptions: state.account.contactsForPinning.map(
                          (contact) => {
                              const {
                                  first_name,
                                  last_name,
                                  phone,
                                  email,
                                  location,
                              } = contact;
                              const name = `${first_name} ${last_name}`;

                              return {
                                  key: contact.id,
                                  value: contact.id,
                                  text: name,
                                  first_name,
                                  last_name,
                                  phone: phone.phone,
                                  phone_id: phone.id,
                                  email: email.email,
                                  email_id: email.id,
                                  location,
                              };
                          }
                      ),
                  }
                : {}),
        }));
    const methods = useForm({
        defaultValues: {
            first_name: "",
            last_name: "",
            email: "",
            street: "",
            suite: "",
            city: "",
            state: "",
            zipcode: "",
            timezone: "America/New_York",
            work_number: "",
            extension: "",
            cell_number: "",
            report_to: 0,
            teams: [],
            profile_type: roles.find((role) =>
                customer ? role.text === "Owner" : role.text === "User"
            )?.value,
            job_title: "",
            ...(contact_id
                ? (() => {
                      const contact = contactOptions.find(
                          (contact) => contact.value === contact_id
                      );
                      return {
                          contact_id: contact_id,
                          cell_number: contact?.phone,
                          email: contact?.email,
                          first_name: contact?.first_name,
                          last_name: contact?.last_name,
                          street: contact?.location?.street,
                          suite: contact?.location?.additional_street,
                          city: contact?.location?.city,
                          state: contact?.location?.state,
                          zipcode: contact?.location?.zip,
                      };
                  })()
                : {}),
        },
        resolver: generateResolver({
            first_name: VALIDATORS.FIRST_NAME().required(
                "This field is required"
            ),
            last_name: VALIDATORS.LAST_NAME().required(
                "This field is required"
            ),
            email: VALIDATORS.EMAIL_ADDRESS().required(
                "This field is required"
            ),
            street: VALIDATORS.STREET_ADDRESS(),
            suite: yup
                .string()
                .no_excessive_whitespaces()
                .noemoji()
                .minlength(2)
                .maxlength(12),
            city: VALIDATORS.TOWN(),
            state: VALIDATORS.STATE().required("This field is required"),
            zipcode: VALIDATORS.US_POSTAL_CODE(),
            work_number: VALIDATORS.PHONE_NUMBER(),
            extension: VALIDATORS.PHONE_NUMBER_EXTENSION(),
            cell_number: VALIDATORS.PHONE_NUMBER(),
        }),
        mode: "onChange",
    });

    const {
        formState: { isValid },
        reset,
        handleSubmit,
    } = methods;

    const dispatch = useDispatch();

    const onAddLogin = async (data) => {
        const {
            first_name,
            last_name,
            email,
            street,
            suite,
            city,
            state,
            zipcode,
            timezone,
            work_number,
            extension,
            cell_number,
            report_to,
            teams,
            profile_type,
            job_title,
        } = data;

        const qaExpirationTime = QAOptionsUtils.getQueryOption("qattl") || null; //allows QA to change the expiration of a new user link
        try {
            if (contact_id && customer) {
                await dispatch(
                    companyActions.createCompany(
                        { ...company, timezone },
                        null,
                        company?.account_id
                    )
                );
                await dispatch(
                    contactActions.updateContact(contact_id, {
                        first_name,
                        last_name,
                        email,
                        job_title,
                    })
                );
                const contact = contactOptions.find(
                    (contact) => contact.value === contact_id
                );
                await dispatch(
                    contactEmailActions.updateContactEmail(contact.email_id, { email: data.email })
                );
            }
            await dispatch(
                authActions.createUser(
                    {
                        first_name,
                        last_name,
                        email,
                        street,
                        suite,
                        city,
                        state,
                        zipcode,
                        timezone,
                        work_number,
                        extension,
                        cell_number,
                        report_to,
                        label: "Office",
                        main: true,
                        profile_type,
                        job_title,
                        teams,
                        ttl: qaExpirationTime,
                    },
                    { customer_id: company?.account_id }
                )
            );
            Notification.alert(
                "Successfully added user!",
                STATUS_TYPES.SUCCESS,
                true
            );
            reset({
                first_name: "",
                last_name: "",
                email: "",
                street: "",
                suite: "",
                city: "",
                state: "",
                zipcode: "",
                timezone: "America/New_York",
                work_number: "",
                extension: "",
                cell_number: "",
                report_to: 0,
                teams: [],
                profile_type: 1,
                job_title: "",
            });
            onClose();
            dispatch(assignActions.readUserAccounts({ limit: 25, page: 1 }));
            if (contact_id && customer) {
                dispatch(userActions.getSummarizedUsers(company.account_id));
            }
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to add user", STATUS_TYPES.ERROR, true);
        }
    };

    return (
        <Modal open={open} onClose={onClose} size="small">
            <Modal.Header>Add New User</Modal.Header>
            <Modal.Content>
                <Form loading={isCreating} noValidate>
                    <FormProvider {...methods}>
                        <UserForm customer={customer} contact_id={contact_id} />
                    </FormProvider>
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                onSave={handleSubmit(onAddLogin)}
                saveDisabled={!isValid || isCheckingDuplicates || isCreating}
                saveOptions={{ loading: isCreating }}
            />
        </Modal>
    );
};

export default AddUserModal;
