import { NestedDropdown } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Divider, Form, Icon, Popup } from "semantic-ui-react";
import '../index.scss'
import { Controller } from "react-hook-form";
import UserSearchField from "shared/forms/UserSearchField";
import { useSelector } from "react-redux";
import { PhoneUtil } from "utils";

const EXCLUDED_PARENTS = [
    "conference_room",
    "parking_lot",
]

const ForwardingUserSection = ({ control, forwardingAudioOptions, external_number, resetField }) => {
    const [destinationOptions, setDestinationOptions] = useState([]);

    const {
        destinations,
        isReadingDestinations,
    } = useSelector((state) => state.pbx_config);

    useEffect(() => {
        if (destinations) {
            const destinationsAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationsAux.map((destination) => {
                const { key } = destination; 
                const options = destinations[key]?.map(({destination_id, label}) => (
                    {key: destination_id, value: destination_id, text: label}
                )) || []
                return {...destination, options};
            })
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    return (
        <div className="ExternalNumberModal__sharedSectionComponent">
            <Divider />
            <div className="ExternalNumberModal__controllerContainer">
                <Controller
                    name={"users[0].user_id"}
                    control={control}
                    style={{flex: 1}}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Field
                            label="User"
                            required
                            className="Event__no-label--Error-fix"
                            control={UserSearchField}
                            excluded_users={[value]}
                            onChange={(_, { name, value: newValue }) => {
                                onChange({ target: { name, value: newValue } });
                            }}
                            value={value}
                            placeholder="Select Users"
                            name={name}
                            error={error && error.message && {
                                content: error.message,
                                pointing: 'below'
                            }}
                            display_selected_user
                            one_user_display
                            unclearable
                            after_load_preselected={() => {
                                resetField(name)
                            }}
                            {...(external_number?.users?.[0]?.user_id ? { preselected: [external_number.users[0].user_id] } : {})}
                        />
                    )}
                />
                <Controller
                    name={"users[0].extension"}
                    control={control}
                    style={{flex: 1}}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { 
                                onChange({ target: { name, value } })
                            }}
                            label="External Ext."
                            placeholder="Type Extension"
                            error={error?.message}
                            className="ExternalNumbers__ruleField"
                        />
                    )}
                />
            </div>
            <div className="ExternalNumberModal__controllerContainer">
                <Controller
                    name={"forward.sound_id"}
                    control={control}
                    style={{flex: 1}}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Select
                            label={{
                                children: (
                                    <span>
                                        Forwarding Audio
                                        <Popup
                                            trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                                            inverted
                                            content="Selected audio will be played before forwarding a call."
                                        />
                                    </span>
                                )
                            }}
                            placeholder="Select audio"
                            options={[{key: "none", value: "none", text: "None"}, ...forwardingAudioOptions]}
                            onChange={async (_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                            error={error?.message}
                            value={value}
                        />
                    )}
                />
                <Controller
                    name={"forward.ringtime"}
                    control={control}
                    style={{flex: 1}}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { value: newValue }) => {
                                if (!newValue) {
                                    onChange({ target: { name, value: 30 } }) 
                                } else {
                                    onChange({ target: { name, value: newValue } }) 
                                }
                            }}
                            placeholder='Select ring duration'
                            selectOnBlur={false}
                            options={PhoneUtil.RING_DURATION_OPTIONS}
                            label="Ring Duration"
                            required
                        />
                    )}
                />
                <Controller
                    name="forward.no_answer_destination_parent"
                    control={control}
                    render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                        <Controller 
                            name="forward.after_destination_id"
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const { name: childName, value: childValue, onChange: onChildChange } = field;
                                return (
                                    <Form.Field
                                        control={NestedDropdown}
                                        child_value={childValue}
                                        parent_value={parentValue}
                                        loading={isReadingDestinations}
                                        nested_options={[{
                                            key: "hang_up",
                                            value: "hang_up",
                                            text: "Hang Up",
                                            options: [],
                                            isSelectable: true,
                                        }, ...destinationOptions]}
                                        onChange={(_, { parent_value, child_value }) => {
                                            onParentChange({ target: { name: parentName, value: parent_value } });
                                            onChildChange({ target: { name: childName, value: parent_value === "hang_up" ? "hang_up" : child_value } });
                                        }}
                                        placeholder="Select destination"
                                        display_parent
                                        selection
                                        label="No Answer Destination"
                                        pointing='top'
                                        required
                                        error={error?.message}
                                        className="ExternalNumbers__destinationRuleField"
                                        excluded_parents={EXCLUDED_PARENTS}
                                    />
                                )
                            }}
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default ForwardingUserSection;
