import React from "react";
import {
    Sidebar,
    Menu,
    Image,
    Grid,
    Icon,
    Divider,
    Popup
} from "semantic-ui-react";
import { NavLink as Link } from "react-router-dom";
import { LogoTextWhite, LogoWhite, ButtonSquare } from 'dyl-components'; 
import "./index.scss";
import { connect } from "react-redux";
import leftSidebarUiActions from "actions/left_sidebar_ui";

const headerMenuItems = [
    { id: "favorites", icon: "star outline", name: "Favorites", left: 115 },
    { id: "recents", icon: "fas fa-history", name: "Recents", left: 115 }
];

const topMenuItems = [
    { id: "dashboard", icon: "dashboard", name: "Dashboard", path: "/", left: 115, end: true }
];

const midMenuItems = [
    { id: "contacts", icon: "fas fa-address-book", name: "Contacts", path: "/contacts", left: 105 },
    { id: "leads", icon: "user", name: "Leads", path: "/leads", left: 85 },
    { id: "opportunities", icon: "fas fa-funnel-dollar", name: "Opportunities", path: "/opportunities", left: 135 },
    { id: "accounts", icon: "fas fa-users", name: "Accounts", path: "/accounts", left: 135 }
];

const lowerMenuItems = [
    { id: 'campaigns', icon: 'fa-solid fa-megaphone', name: 'Campaigns', path: '/campaigns' },
    { id: "groups", icon: "folder", name: "Groups", path: "/groups", left: 140 },
    { id: "calendar", icon: "fas fa-calendar-alt", name: "Calendar", path: "/calendar", left: 105 }
];

const reportsMenu = [
    { id: 'call_history', path: '/reports/pbx-log', name: 'Call History Log', icon: 'call', left: 125 },
    { id: 'text_log', path: '/reports/sms-report', name: 'Texts Log', icon: 'fas fa-comment', left: 97 },
    { id: 'fax_history', path: '/reports/fax-log', name: 'Fax Log', icon: 'fax', left: 120 },    
    { id: 'quotes_orders', path: '/quotes-orders', name: 'Quotes and Orders', icon: 'fas fa-box-dollar', left: 160 },
    { id: 'invoices', path: '/invoices', name: 'Invoices', icon: 'fas fa-file-invoice-dollar', left: 160 }
];

class LeftSidebar extends React.Component {
    state = {
        isReportsOpen: false
    };

    createButtonProperties = name => {
        return {
            active: false,
            onClick: () => {
                this.props.onSetMenuActive(name);
                this.props.onToggleSecondarySidebar(name);
            },
            className: "DYL2Menu__item DYL2Menu__item"
        };
    };

    isActive = item => {
        return this.props.activeMenuItem === item;
    };

    createMenuItemProperties = (name, permission) => {
        let disabled = false;
        return {
            active: false,
            disabled,
            onClick: () => {
                this.props.onSetMenuActive(name);
                this.props.onCloseSecondarySidebar();
            },
            className: `DYL2Menu__item DYL2Menu__item`
        };
    };

    onToggleReports = () => {
        this.setState(prevState => ({
            isReportsOpen: !prevState.isReportsOpen
        }))
    }

    renderMenuItem(path, id, icon, name, permission) {
        // PERMISSION_DISABLED //add back below
        // const { permissionCheckingProp, isAccessibleProp } = PermissionUtils;
        // const disabled = this.props[permissionCheckingProp(permission)] || !this.props[isAccessibleProp(permission)] //add  back for permission check
        const disabled = false; //remove

        return <Menu.Item
            className={`DYL2Menu__item DYL2Menu__subitem DYL2Menu__subitem${this.props.activeSubMenuItem === id ? '--active' : ''}`}
            as={Link} to={path} onClick={() => this.props.onSetSubMenuActive(id)}
            disabled={disabled}
        >
            {!disabled ? (
                <Icon name={icon} color={this.props.activeSubMenuItem === id && 'yellow'} className="Menu__icon" />
            ) : (
                <Icon name='circle notched' loading />
            )}
            {this.props.isSidebarOpen ? name : ''}
        </Menu.Item>;
    }

    render() {
        const img = this.props.isSidebarOpen
            ? { src: LogoTextWhite }
            : { src: LogoWhite };
        return (
            <Sidebar
                as={Menu}
                inverted
                className={`DYL2Menu DYL2Menu--${this.props.isSidebarOpen ? "open" : "closed"
                    } DYL2Menu--${this.state.isReportsOpen ? "scroll" : ""} `}
                visible={true}
            >
                <div className="DYL2Logo" >
                    <Image src={img.src} />
                </div>

                <Grid className="DYL2MenuGrid" container stackable>
                    <Grid.Row columns={this.props.isSidebarOpen ? "equal" : 1} style={{ padding: 0, marginTop: this.props.isSidebarOpen ? 0 : 7}}>                        <Grid.Column textAlign={this.props.isSidebarOpen ? 'left' : 'center'} style={this.props.isSidebarOpen ? { paddingLeft: 6, paddingRight: 0, paddingTop: 7, paddingBottom: 20 } : { paddingLeft: 0.1, paddingRight: 0, paddingBottom: 6 }}>  
                            <Popup
                                trigger={
                                    <ButtonSquare 
                                        text="View" 
                                        icon="fa-solid fa-phone-office" 
                                        onClick={() => { this.props.onToggleSecondarySidebar("view"); }} 
                                    />
                                }
                                inverted
                                content='Office View'
                                position={
                                    this.props.isSidebarOpen
                                        ? "top left"
                                        : this.state.isReportsOpen ? "left center" : "right center"
                                }
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={this.props.isSidebarOpen ? 'left' : 'center'} style={this.props.isSidebarOpen ? { paddingLeft: 5, paddingRight: 0, paddingTop: 7, paddingBottom: 20 } : { paddingLeft: 0.1, paddingRight: 0, paddingBottom: 6 }}>
                            <Popup
                                trigger={
                                    <ButtonSquare 
                                        text="Chat" 
                                        icon="fa-solid fa-message-lines" 
                                        disabled
                                        onClick={() => {
                                            this.props.onToggleSecondarySidebar("chat");
                                        }}
                                    />
                                }
                                inverted
                                content='Chat Room'
                                position={
                                    this.props.isSidebarOpen
                                        ? "top center"
                                        : this.state.isReportsOpen ? "left center" : "right center"
                                }
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={this.props.isSidebarOpen ? 'left' : 'center'} style={this.props.isSidebarOpen ? { paddingLeft: 5, paddingRight: 0, paddingTop: 7, paddingBottom: 20 } : { paddingLeft: 0.1, paddingRight: 0, paddingBottom: 20 }}>
                            <Popup
                                trigger={
                                    <ButtonSquare 
                                        text="Text" 
                                        icon="fa-solid fa-comments" 
                                        disabled
                                    />
                                }
                                inverted
                                content='Texting'
                                position={
                                    this.props.isSidebarOpen
                                        ? "top center"
                                        : this.state.isReportsOpen ? "left center" : "right center"
                                }
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                {headerMenuItems.map(({ id, icon, name, left }) =>
                    !this.props.isSidebarOpen ? (
                        <Popup
                            trigger={
                                <Menu.Item
                                    {...this.createButtonProperties(id)}
                                >
                                    <Icon name={icon} className="Menu__icon" />
                                </Menu.Item>
                            }
                            content={name}
                            inverted
                            style={{ left: this.state.isReportsOpen ? left : 0 }}
                            position={this.state.isReportsOpen ? "left center" : "right center"}
                        />
                    ) : (
                        <Menu.Item
                            {...this.createButtonProperties(id)}

                        >
                            <Icon name={icon} className="Menu__icon" />
                            {name}
                        </Menu.Item>
                    )
                )}

                {this.props.isSidebarOpen && <Divider className='Menu__Divider' />}
                {topMenuItems.map(({ id, icon, name, path, left, permission, end }) =>
                    !this.props.isSidebarOpen ? (
                        <Popup
                            trigger={
                                <Menu.Item
                                    {...this.createMenuItemProperties(id, permission)}
                                    as={Link}
                                    to={path}
                                >
                                    <Icon name={icon} className="Menu__icon" />
                                </Menu.Item>
                            }
                            content={name}
                            inverted
                            style={{ left: this.state.isReportsOpen ? left : 0 }}
                            position={this.state.isReportsOpen ? "left center" : "right center"}
                        />
                    ) : (
                        <Menu.Item
                            {...this.createMenuItemProperties(id, permission)}
                            as={Link}
                            to={path}
                            end={end}
                        >
                            <Icon name={icon} className="Menu__icon" />
                            {name}
                        </Menu.Item>
                    )
                )}

                {this.props.isSidebarOpen && <Divider className='Menu__Divider' />}
                {midMenuItems.map(({ id, icon, name, path, left, permission }) =>
                    !this.props.isSidebarOpen ? (
                        <Popup
                            trigger={
                                <Menu.Item
                                    {...this.createMenuItemProperties(id, permission)}
                                    as={Link}
                                    to={path}
                                >
                                    <Icon name={icon} className="Menu__icon" />
                                </Menu.Item>
                            }
                            content={name}
                            inverted
                            style={{ left: this.state.isReportsOpen ? left : 0 }}
                            position={this.state.isReportsOpen ? "left center" : "right center"}
                        />
                    ) : (
                        <Menu.Item
                            {...this.createMenuItemProperties(id, permission)}
                            as={Link}
                            to={path}
                        >
                            <Icon name={icon} className="Menu__icon" />
                            {name}
                        </Menu.Item>
                    )
                )}

                {this.props.isSidebarOpen && <Divider className='Menu__Divider' />}
                {lowerMenuItems.map(({ id, icon, name, path, left, permission }) =>
                    !this.props.isSidebarOpen ? (
                        <Popup
                            trigger={
                                <Menu.Item
                                    {...this.createMenuItemProperties(id, permission)}
                                    as={Link}
                                    to={path}
                                >
                                    <Icon name={icon} className="Menu__icon" />
                                </Menu.Item>
                            }
                            content={name}
                            inverted
                            style={{ left: this.state.isReportsOpen ? left : 0 }}
                            position={this.state.isReportsOpen ? "left center" : "right center"}
                        />
                    ) : (
                        <Menu.Item
                            {...this.createMenuItemProperties(id, permission)}
                            as={Link}
                            to={path}
                        >
                            <Icon name={icon} className="Menu__icon" />
                            {name}
                        </Menu.Item>
                    )
                )}

                {this.props.isSidebarOpen && <Divider className='Menu__Divider' />}
                {
                    !this.props.isSidebarOpen ? (
                        <Popup
                            trigger={
                                <Menu.Item
                                    className="DYL2Menu__item"
                                    onClick={this.onToggleReports}
                                >
                                    <Icon.Group className="Menu__icon">
                                        <Icon name="pie chart" />{" "}
                                        <Icon name={`caret ${this.state.isReportsOpen ? 'down' : 'right'}`} />
                                    </Icon.Group>
                                </Menu.Item>
                            }
                            content={"Reports"}
                            inverted
                            style={{ left: this.state.isReportsOpen ? 100 : 0 }}
                            position={this.state.isReportsOpen ? "left center" : "right center"}
                        />
                    ) : (
                        <Menu.Item
                            className='DYL2Menu__item'
                            onClick={this.onToggleReports}
                        >
                            <Icon name={'pie chart'} className="Menu__icon" />
                            Reports/Logs
                            <Icon name={`caret ${this.state.isReportsOpen ? 'down' : 'right'}`} style={{ position: 'relative', left: '1.4em' }} />
                        </Menu.Item>
                    )
                }

                {
                    this.state.isReportsOpen &&
                    reportsMenu.map(({ name, icon, path, left, permission, id }) => (
                        !this.props.isSidebarOpen ? (
                            <Popup
                                trigger={
                                    this.renderMenuItem(path, id, icon, name, permission)
                                }
                                content={name}
                                inverted
                                style={{ left }}
                                position={this.state.isReportsOpen ? "left center" : "right center"}
                            />
                        ) : (
                            this.renderMenuItem(path, id, icon, name, permission)
                        )
                    ))
                }
            </Sidebar >
        );
    }
}

const mapStateToProps = state => {
    return ({
        activeMenuItem: state.left_sidebar_ui.activeMenuItem,
        activeSubMenuItem: state.left_sidebar_ui.activeSubMenuItem
    })
}

const mapDispatchToProps = dispatch => ({
    onSetMenuActive: (activeMenuItem) => {
        dispatch(leftSidebarUiActions.onSetMenuActive(activeMenuItem));
    },
    onSetSubMenuActive: (activeSubMenuItem) => {
        dispatch(leftSidebarUiActions.onSetSubMenuActive(activeSubMenuItem));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar)

