import { ButtonLink } from "dyl-components";
import React from "react";
import { Divider, Form, Icon } from "semantic-ui-react";
import '../index.scss'
import { Controller } from "react-hook-form";
import UserSearchField from "shared/forms/UserSearchField";


const SharedSection = ({ control, onAddUser, fields, onDeleteUser, external_number, resetField }) => {
    const isDeletable = fields?.length > 1;
    return (
        <div className="ExternalNumberModal__sharedSectionComponent">
            <Divider />
            {fields?.map((_, idx) => {
                return (
                    <div className="ExternalNumberModal__controllerContainer">
                        <Controller
                            name={`users[${idx}].user_id`}
                            control={control}
                            style={{flex: 1}}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Field
                                    key={idx}
                                    label="User"
                                    required
                                    className="Event__no-label--Error-fix"
                                    control={UserSearchField}
                                    excluded_users={[value]}
                                    onChange={(_, { name, value: newValue }) => {
                                        onChange({ target: { name, value: newValue } });
                                    }}
                                    value={value}
                                    placeholder="Select Users"
                                    name={name}
                                    error={error && error.message && {
                                        content: error.message,
                                        pointing: 'below'
                                    }}
                                    display_selected_user
                                    one_user_display
                                    unclearable
                                    after_load_preselected={() => {
                                        resetField(name)
                                    }}
                                    {...(external_number?.users?.[idx]?.user_id ? { preselected: [external_number.users[idx].user_id] } : {})}
                                />
                            )}
                        />
                        <Controller
                            name={`users[${idx}].extension`}
                            control={control}
                            style={{flex: 1}}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { 
                                        onChange({ target: { name, value } })
                                    }}
                                    label="External Ext."
                                    placeholder="Type Extension"
                                    error={error?.message}
                                    className="ExternalNumbers__ruleField"
                                />
                            )}
                        />
                        {isDeletable && (
                            <ButtonLink onClick={() => onDeleteUser(idx)}>
                                <Icon className="fa-solid fa-trash redIcon" />
                            </ButtonLink>
                         )}
                    </div>
                )
            })}
            <ButtonLink onClick={onAddUser}>
                <Icon className="fa-solid fa-plus ExternalNumberModal__blackIcon"/> Add New User
            </ButtonLink>
        </div>
    )
}

export default SharedSection;
